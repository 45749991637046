import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { getData } from "../../utils/DatabaseActions";
import { Loading } from "../Loading/Loading";

const Videos = () => {
  const [videos, setVideos] = useState(null);
  const [loading, setLoading] = useState(true);

  const [videoUrl, setVideoUrl] = useState(
    "https://www.youtube.com/watch?v=HSPIpwPdTIA"
  );

  useEffect(() => {
    getData("videos", setVideos, setLoading);
    // setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div id="videos">
          <div className="video">
            <ReactPlayer
              className="react-player"
              url={videoUrl}
              width="100%"
              height="100%"
              controls={true}
            />
          </div>
          <ul className="navigation">
            {videos.map((el) => (
              <li onClick={() => setVideoUrl(el.videoUrl)} key={el.id}>
                <i className="fas fa-play"></i>
                <img
                  src={`https://i.ytimg.com/vi/${el.videoUrl.split("=")[1]}/hqdefault.jpg`}
                  alt={`video-${el.id}`}
                  />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Videos;
