import React, { useState, useEffect } from "react";
import { Loading } from "../../Loading/Loading";

import { getData, addData, deleteData } from "../../../utils/DatabaseActions";

export const Events = () => {
  const [events, setEvents] = useState([]);

  const [loading, setLoading] = useState(true);

  const [warningMessage, setWarningMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    getData("events", setEvents, setLoading);
  }, []);

  const onChangeEvents = (e) => {
    if (e.target.value.toLowerCase() === "üres") e.target.value = " ";
    if (e.target.value.toLowerCase() === "front") e.target.value = " ";
    if (e.target.value.toLowerCase() === "back") e.target.value = " ";
    if (e.target.value.toLowerCase() === "current") e.target.value = " ";
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitEvents = (e) => {
    e.preventDefault();
    addData("events/", formData, events.length, setSuccessMessage);
  };

  return (
    <>
      {successMessage && (
        <div className="bg-green-500 rounded-md p-2">
          <p>{successMessage}</p>
        </div>
      )}
      {warningMessage && (
        <div className="bg-red-500 rounded-md p-2">
          <p>{warningMessage}</p>
        </div>
      )}

      {!loading ? (
        <div className="flex flex-col items-center justify-center w-full h-fit">
          <h1 className="text-zinc-200 font-thin my-2 text-2xl text-center uppercase">
            Események
          </h1>
          <div className="w-full flex flex-wrap flex-col justify-center items-center lg:flex-row">
            {events.map((e) => (
              <div
                className="relative flex flex-col bg-zinc-700 w-[300px] h-[400px] p-5 m-2"
                key={e.id}
              >
                {<img className="w-42 lg:w-36" src={e.image} alt={e.alt}></img>}
                {<p className="text-zinc-200">Sorszám: {e.id}</p>}
                {
                  <p className="text-zinc-200">
                    Név: <strong>{e.name}</strong>
                  </p>
                }
                {<p className="text-zinc-200">Helyszín: {e.location}</p>}
                {<p className="text-zinc-200">Dátum: {e.date}</p>}
                {
                  <p className="text-zinc-200">
                    Elhelyezkedés: {e.position ? e.position : "Üres"}
                  </p>
                }
                {
                  <p className="text-zinc-200 max-w-[200px] break-all">
                    Link: {e.link}
                  </p>
                }
                <div className="absolute bottom-4 buttons">
                  <button
                    onClick={() =>
                      deleteData("events/", e.id - 1, setSuccessMessage)
                    }
                  >
                    <i className="far fa-trash-alt text-red-400"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-center p-5">
            <form
              onSubmit={onSubmitEvents}
              className="flex flex-col items-center justify-center"
            >
              <input
                type="number"
                name="id"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder={`A következő: ${events.length + 1}`}
                onChange={onChangeEvents}
                required
              />
              <input
                type="text"
                name="name"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder="Megnevezés"
                onChange={onChangeEvents}
                required
              />
              <input
                type="text"
                name="location"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder="Helyszín"
                onChange={onChangeEvents}
                required
              />
              <input
                type="text"
                name="image"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder="Kép elérési útvonala"
                onChange={onChangeEvents}
                required
              />
              <input
                type="text"
                name="alt"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder="Kép megnevezése"
                onChange={onChangeEvents}
                required
              />
              <input
                type="date"
                name="date"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder="Dátum"
                onChange={onChangeEvents}
                required
              />
              <input
                type="text"
                name="link"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder="Link"
                onChange={onChangeEvents}
                required
              />
              <input
                type="text"
                name="position"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-1"
                placeholder="Front-Current-Back-Üres"
                onChange={onChangeEvents}
                required
              />
              <button className="text-zinc-800 bg-green-400 w-fit py-2 rounded-lg px-4 mt-4 uppercase font-medium">
                Hozzáadás
              </button>
            </form>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
