import React from "react";
import BrassLoading from "./Brass-loading.svg";

export const Loading = () => {
  return (
    <>
      <img
        src={BrassLoading}
        style={{
          position: "absolute",
          width: "100px",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        alt="BrassLoading"
      />
    </>
  );
};
