import React, { Fragment, useEffect, useState, useContext } from "react";
import { Events } from "../Events/Events";
import { Loading } from "../Loading/Loading";

import LanguageContext from "../../utils/LanguageContext";
import database from "../../local_database/db.json";

export const Event = () => {
  const [titles, setTitles] = useState(null);
  const [loading, setLoading] = useState(true);

  const language = useContext(LanguageContext);

  useEffect(() => {
    if (language === "hu") {
      setTitles(database.titles);
      setLoading(false);
    } else if (language === "en") {
      setTitles(database.titles_en);
      setLoading(false);
    } else if (language === "de") {
      setTitles(database.titles_de);
      setLoading(false);
    }
  }, [language]);

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <section id="events">
          <h2>{titles[0].events}</h2>
          <div className="flex items-center justify-center w-fit mx-10 xl:mx-32">
            <ul className="flex flex-row flex-wrap overflow-scroll h-[430px] gap-5">
              <Events />
            </ul>
            <div
              className="data_div"
              style={{ position: "absolute", top: "4rem" }}
            ></div>
          </div>
        </section>
      )}
    </Fragment>
  );
};
