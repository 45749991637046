import React, { Fragment, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "firebase/auth";
import "./Login.css";

export const Login = () => {
  const [error, setError] = useState("");
  const auth = getAuth();

  const signInWithEmail = (e) => {
    e.preventDefault();

    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError("Belépés megtagadva.");
        setTimeout(() => {
          setError("");
          window.location.replace("/");
        }, 3000);
      });
  };

  return (
    <Fragment>
      <div className="login">
        <a href="/">Vissza</a>

        {error ? (
          <div className="errorMessage ">
            <p>{error}</p>
          </div>
        ) : (
          ""
        )}

        <div className="wrapper">
          <h2>
            Voice<span>&</span>Brass
          </h2>
          <p>WWW.VOICEANDBRASS.HU</p>
          <form>
            <label htmlFor="email">Email</label>
            <input id="email" type="text" placeholder="Email megadása" />
            <label htmlFor="password">Jelszó</label>
            <input
              id="password"
              type="password"
              placeholder="Jelszó megadása"
            />
            <button onClick={signInWithEmail}>Bejelentkezés</button>
          </form>
          <span>(Bejelentkezés Email fiókkal)</span>
        </div>
      </div>
    </Fragment>
  );
};
