import React, { useEffect, useState, useContext } from "react";
import "./Media.css";

import LanguageContext from "../../utils/LanguageContext";
import { Loading } from "../Loading/Loading";
import database from "../../local_database/db.json";

import Pictures from "./Pictures";
import Videos from "./Videos";

export const Media = () => {
  const [titles, setTitles] = useState(null);
  const [loading, setLoading] = useState(true);
  const language = useContext(LanguageContext);

  useEffect(() => {
    if (language === "hu") {
      setTitles(database.titles);
      setLoading(false);
    } else if (language === "en") {
      setTitles(database.titles_en);
      setLoading(false);
    } else if (language === "de") {
      setTitles(database.titles_de);
      setLoading(false);
    }
  }, [language]);

  return (
    <>
      <section id="media">
        {loading ? <Loading /> : <h2>{titles[0].media}</h2>}
        <Videos />
        <Pictures />
      </section>
    </>
  );
};
