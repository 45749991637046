import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCLAeEu5wxTiDrgeElMPfnNKBstBSDChg4",
  authDomain: "voice-and-brass.firebaseapp.com",
  projectId: "voice-and-brass",
  storageBucket: "voice-and-brass.appspot.com",
  databaseURL: "https://voice-and-brass-default-rtdb.firebaseio.com/",
  messagingSenderId: "590634706620",
  appId: "1:590634706620:web:d3ed2e29dc51277528d2b9",
  disableSignUp: true,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const database = getDatabase(app);

export { auth, provider, database };
