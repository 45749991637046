import React, { useState, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Loading } from "../Loading/Loading";
import { getData } from "../../utils/DatabaseActions";

const Pictures = () => {
  const [pictures, setPictures] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData("pictures", setPictures, setLoading);
    // setLoading(false);
  }, []);

  return (
    <div className="pictures">
      {loading ? (
        <Loading />
      ) : (
        <ScrollContainer className="scroll-container">
          <div className="picture">
            {pictures.map((picture) => (
              <img
                src={picture.image}
                alt={picture.alt}
                key={picture.id}
                onClick={() => window.open(picture.image, "_blank")}
              />
            ))}
          </div>
        </ScrollContainer>
      )}
    </div>
  );
};

export default Pictures;
