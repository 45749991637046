import {
  getDatabase,
  ref,
  child,
  set,
  get,
  update,
  remove,
} from "firebase/database";

// GET
export const getData = (type, typeState, loading) => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `${type}`))
    .then((data) => {
      if (data.exists()) {
        typeState(data.val());
        loading(false);
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

// ADD
export const addData = (type, data, id, messageState) => {
  const db = getDatabase();

  if (type === "pictures/") {
    set(ref(db, `${type}` + id), {
      id: id + 1,
      alt: data.alt,
      image: data.image,
    })
      .then(() => {
        messageState(`Adat hozzáadva. Kategória: ${type}. Oldal frissítése...`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      })
      .catch((err) => {
        messageState(`Probléma. Bővebben: ${err}`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      });
  }

  if (type === "events/") {
    set(ref(db, `${type}` + id), {
      id: id + 1,
      alt: data.alt,
      date: data.date,
      image: data.image,
      location: data.location,
      link: data.link,
      name: data.name,
      position: data.position,
    })
      .then(() => {
        messageState(`Adat hozzáadva. Kategória: ${type}. Oldal frissítése...`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      })
      .catch((err) => {
        messageState(`Probléma. Bővebben: ${err}`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      });
  }

  if (type === "videos/") {
    set(ref(db, `${type}` + id), {
      id: id + 1,
      videoUrl: data.videoUrl,
    })
      .then(() => {
        messageState(`Adat hozzáadva. Kategória: ${type}. Oldal frissítése...`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      })
      .catch((err) => {
        messageState(`Probléma. Bővebben: ${err}`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      });
  }
};

// UPDATE
export const updateData = (type, data, id, messageState) => {
  const db = getDatabase();

  if (type === "paragraphs") {
    update(ref(db, "paragraphs/" + id), {
      id: id,
      text: data.text,
    })
      .then(() => {
        messageState(`Adat módosítva. Kategória: ${type}. Oldal frissítése...`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      })
      .catch((err) => {
        messageState(`Probléma. Bővebben: ${err}`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      });
  }

  if (type === "soloAchievements/") {
    update(ref(db, `${type}` + id), {
      id: id + 1,
      text: data.text,
    })
      .then(() => {
        messageState(`Adat módosítva. Kategória: ${type}. Oldal frissítése...`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      })
      .catch((err) => {
        messageState(`Probléma. Bővebben: ${err}`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      });
  }

  if (type === "bandAchievements/") {
    update(ref(db, `${type}` + id), {
      id: id + 1,
      text: data.text,
    })
      .then(() => {
        messageState(`Adat módosítva. Kategória: ${type}. Oldal frissítése...`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      })
      .catch((err) => {
        messageState(`Probléma. Bővebben: ${err}`);
        setTimeout(() => {
          window.location.reload(false);
          messageState("");
        }, 2000);
      });
  }
};

// DELETE
export const deleteData = (type, id, messageState) => {
  const db = getDatabase();

  if (type === "pictures/") {
    try {
      remove(ref(db, `${type}` + id));
      messageState(`Az ${id}. sorszámú ${type} törölve`);
      setTimeout(() => {
        window.location.reload(false);
        messageState("");
      }, 2000);
    } catch (err) {
      messageState(`Probléma. Bővebben: ${err}`);
      setTimeout(() => {
        window.location.reload(false);
        messageState("");
      }, 2000);
    }
  }

  if (type === "videos/") {
    try {
      remove(ref(db, `${type}` + id));
      messageState(`Az ${id}. sorszámú ${type} törölve`);
      setTimeout(() => {
        window.location.reload(false);
        messageState("");
      }, 2000);
    } catch (err) {
      messageState(`Probléma. Bővebben: ${err}`);
      setTimeout(() => {
        window.location.reload(false);
        messageState("");
      }, 2000);
    }
  }

  if (type === "events/") {
    try {
      remove(ref(db, `${type}` + id));
      messageState(`Az ${id}. sorszámú ${type} törölve`);
      setTimeout(() => {
        window.location.reload(false);
        messageState("");
      }, 2000);
    } catch (err) {
      messageState(`Probléma. Bővebben: ${err}`);
      setTimeout(() => {
        window.location.reload(false);
        messageState("");
      }, 2000);
    }
  }
};
