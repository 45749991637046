import React, { useState, useEffect, useContext } from "react";

import LanguageContext from "../../utils/LanguageContext";
import database from "../../local_database/db.json";

export const Contact = () => {
  const [titles, setTitles] = useState(null);
  const [loading, setLoading] = useState(true);
  const language = useContext(LanguageContext);

  useEffect(() => {
    if (language === "hu") {
      setTitles(database.titles);
      setLoading(false);
    } else if (language === "en") {
      setTitles(database.titles_en);
      setLoading(false);
    } else if (language === "de") {
      setTitles(database.titles_de);
      setLoading(false);
    }
  }, [language]);

  return (
    <>
      <section id="contact">
        <div className="wrapper">
          <div className="social">
            <a
              href="https://www.facebook.com/voiceandbrass"
              target="_blank"
              rel="noreferrer"
            >
              <i className="icon-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/voiceandbrass/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="icon-instagram"></i>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
              <i className="icon-twitter"></i>
            </a>
          </div>
          <div className="blurb">
            <div className="card">
              <h3>
                {loading ? "Loading..." : titles[0].contact1}
                <br />
                <strong>Hagyánek Barbara</strong>
              </h3>
              <p>
                <i className="fas fa-mobile-alt"></i> +36-70-456-0537
              </p>
            </div>
            <div className="card">
              {" "}
              <h3>
                {loading ? "Loading..." : titles[0].contact2}
                <br />
                <strong>Kuna Márton</strong>
              </h3>
              <p>
                <i className="fas fa-mobile-alt"></i> +36-20-517-1170
              </p>
            </div>
            <div className="card">
              <h3>
                {loading ? "Loading..." : titles[0].contact3}
                <br />
                <strong>Ferencz-Kuna Valéria</strong>
              </h3>
              <p>
                <i className="fas fa-mobile-alt"></i> +36-31-783-9098
              </p>
            </div>
          </div>
          <p>
            <i className="fas fa-envelope"></i> VOICEANDBRASS@GMAIL.COM
          </p>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h3 style={{ fontSize: "25px", fontWeight: "bold" }}>Ferencz-Kuna Zeneművészeti Alapítvány</h3>
            <h2 style={{ fontWeight: "normal" }}>Pénzügyi beszámoló a tavalyi lezárt pénzügyi évről</h2>

            <button 
              onClick={() => window.open("assets/pdf/2023_szamviteli_beszamolo.pdf", "_blank")}
              style={{ 
                background: "#909090", 
                color: "white", 
                borderRadius: '5px', 
                width: "fit-content", 
                paddingLeft: "20px", 
                paddingRight: "20px", 
                paddingTop: "5px", 
                paddingBottom: "5px", 
                marginTop: "10px" 
                }}
              >
                LETÖLTÉS
            </button>
          </div>
        </div>
        
        <div id="copyright">
          <a href="http://www.hmartx.com" target="_blank" rel="noreferrer">
            <p> HMARTX.COM &copy; 2021</p>
          </a>
        </div>
      </section>
    </>
  );
};
