import React, { Fragment, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Hero } from "./components/Hero/Hero";
import { About } from "./components/About/About";
import { Media } from "./components/Media/Media";
import { Event } from "./components/Event/Event";
import { Contact } from "./components/Contact/Contact";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { Login } from "./components/Login/Login";
import { ToTheTop } from "./components/ToTheTop/ToTheTop";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import { NotFound } from "./components/NotFound/NotFound";

import LanguageContext from "./utils/LanguageContext";

function App() {
  const [user] = useAuthState(auth);
  const [language, setLanguage] = useState("hu");

  const handleChange = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <LanguageContext.Provider value={language}>
      <Router>
        <Fragment>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <div className="flex flex-col max-w-[100px]">
                    <select
                      className="fixed left-[2.5rem] top-[2.5rem] min-w-[50px] px-2 py-0.5 text-md z-50 rounded-xl"
                      name="languages"
                      id="languages"
                      value={language}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="hu">HU</option>
                      <option value="en">EN</option>
                      <option value="de">DE</option>
                    </select>
                  </div>

                  <Hero />
                  <About />
                  <Event />
                  <Media />
                  <Contact />
                  <ToTheTop />
                </>
              }
            ></Route>
            <Route exact path="*" element={<NotFound />}></Route>
            {!user ? (
              <Route path="/dashboard" element={<Login />} />
            ) : (
              <Route path="/dashboard" element={<Dashboard />} />
            )}
          </Routes>
        </Fragment>
      </Router>
    </LanguageContext.Provider>
  );
}

export default App;
