import React, { useState, useEffect, useContext } from "react";
import { Loading } from "../Loading/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./About.css";
import LanguageContext from "../../utils/LanguageContext";
import database from "../../local_database/db.json";

export const About = () => {
  const [paragraphs, setParagraphs] = useState(null);
  const [bandAchievements, setBandAchievements] = useState(null);
  const [soloAchievements, setSoloAchievements] = useState(null);
  const [titles, setTitles] = useState(null);
  const [loading, setLoading] = useState(true);

  const language = useContext(LanguageContext);

  useEffect(() => {
    if (language === "hu") {
      setSoloAchievements(database.soloAchievements);
      setBandAchievements(database.bandAchievements);
      setParagraphs(database.paragraphs);
      setTitles(database.titles);
      setLoading(false);
    } else if (language === "en") {
      setSoloAchievements(database.soloAchievements_en);
      setBandAchievements(database.bandAchievements_en);
      setParagraphs(database.paragraphs_en);
      setTitles(database.titles_en);
      setLoading(false);
    } else if (language === "de") {
      setSoloAchievements(database.soloAchievements_de);
      setBandAchievements(database.bandAchievements_de);
      setParagraphs(database.paragraphs_de);
      setTitles(database.titles_de);
      setLoading(false);
    }
  }, [language]);

  return (
    <div>
      <section id="about">
        <div className="wrapper">
          <div className="blurb">
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="header">
                  <div className="header__left">
                    <h2>{titles[0].bio}</h2>
                    {paragraphs && paragraphs.map((el, index) => (
                      <div key={el.text + index}>
                        <p>{el.text}</p>
                        <br />
                      </div>
                    ))}
                  </div>

                  <div className="header__right">
                    <h2>{titles[0].band}</h2>
                    <div className="band-achievements">
                      {bandAchievements.map((el, index) => (
                        <div key={el.id} className="band-achievements card">
                          <i className="fas fa-award"></i>
                          <p>{el.text}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="header__middle">
                <div className="title">
                    <h2>{titles[0].solo}</h2>
                  </div>

                  <div className="solo-achievements">
                    {soloAchievements && soloAchievements.map((soloAchievement, index) => (
                      <div className="solo-achievements card" key={index}>
                      <i className="fas fa-award"></i>
                      <p>
                        <strong>{soloAchievement.name}</strong>
                      </p>
                      <p>{soloAchievement.instrument}</p>
                      {soloAchievement.text.map((el) => (
                        <p key={el.id}>{el}</p>
                      ))}
                    </div>
                    ))}
                  </div>
                </div>
                <div className="container">
                  <div className="card">
                    <div className="card--title">
                      <h1>
                        Voice<span>&</span>Brass
                      </h1>
                    </div>
                    <div className="card--image">
                      <LazyLoadImage
                        src="assets/img/about/voice_and_brass_mod.jpg"
                        alt="voice_and_brass"
                      />
                    </div>

                    {soloAchievements && soloAchievements.map((soloAchievement, index) => (
                    <div className={`card--info info--${index + 1}`} key={index}>
                    <span className="info--text">
                      {soloAchievement.name} -{" "}
                      <strong>{soloAchievement.instrument}</strong> <br />{" "}
                      {soloAchievement.text.map((el) => (
                        <p key={el.id}>{el}</p>
                      ))}
                    </span>
                    <div className="icon">
                      <ion-icon name="add-outline"></ion-icon>
                    </div>
                    <div className="info--image"></div>
                    </div>
                    ))}

                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
