import React, { useState, useEffect } from "react";
import { getData, addData, deleteData } from "../../../utils/DatabaseActions";
import { Loading } from "../../Loading/Loading";

export const Pictures = () => {
  const [pictures, setPictures] = useState([]);

  const [loading, setLoading] = useState(true);

  const [warningMessage, setWarningMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    getData("pictures", setPictures, setLoading);
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitPicture = (e) => {
    e.preventDefault();
    addData("pictures/", formData, Number(pictures.length), setSuccessMessage);
  };

  return (
    <>
      {successMessage && (
        <div className="bg-green-500 rounded-md p-2">
          <p>{successMessage}</p>
        </div>
      )}
      {warningMessage && (
        <div className="bg-red-500 rounded-md p-2">
          <p>{warningMessage}</p>
        </div>
      )}

      {!loading ? (
        <>
          <h1 className="text-zinc-200 font-thin my-2 text-2xl text-center uppercase">
            Képek
          </h1>
          <div className="flex flex-col items-center justify-center w-full h-fit lg:flex-row">
            {pictures.map((p) => (
              <div
                key={p.id}
                className="flex items-center justify-center p-2 w-full "
              >
                <div
                  className="flex flex-col items-center justify-center"
                  key={p.id}
                >
                  <img className="w-52" src={p.image} alt={p.alt} />
                  {<p className="text-zinc-300 text-md">Megnevezés: {p.alt}</p>}
                  {<p className="text-zinc-300 text-md">Link: {p.image}</p>}
                  <div className="buttons">
                    <button
                      onClick={() =>
                        deleteData("pictures/", p.id - 1, setSuccessMessage)
                      }
                    >
                      <i className="far fa-trash-alt text-red-400"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center p-5">
            <form
              onSubmit={onSubmitPicture}
              className="flex flex-col items-center"
            >
              <input
                type="text"
                className="h-10 p-2 bg-zinc-700 text-zinc-300 outline-none my-4"
                name="id"
                onChange={onChange}
                placeholder={`Javasolt: ${pictures.length + 1}`}
                required
              />
              <input
                type="text"
                className="h-10 p-2 bg-zinc-700 text-zinc-100 outline-none my-4"
                name="image"
                onChange={onChange}
                placeholder="Link"
                required
              />
              <input
                type="text"
                className="h-10 p-2 bg-zinc-700 text-zinc-100 outline-none my-4"
                name="alt"
                onChange={onChange}
                placeholder="Megnevezés"
                required
              />
              <button className="text-zinc-800 bg-green-400 w-fit py-2 rounded-lg px-4 mt-4 uppercase font-medium">
                Hozzáadás
              </button>
            </form>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
