import React, { useContext, useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loading } from "../Loading/Loading";

import database from "../../local_database/db.json";
import LanguageContext from "../../utils/LanguageContext";

export const Hero = () => {
  const [titles, setTitles] = useState(null);
  const [menu, setMenu] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(true);

  const language = useContext(LanguageContext);

  useEffect(() => {
    if (language === "hu") {
      setTitles(database.titles);
      setMenu(database.menu);
      setLoading(false);
    } else if (language === "en") {
      setTitles(database.titles_en);
      setMenu(database.menu_en);
      setLoading(false);
    } else if (language === "de") {
      setTitles(database.titles_de);
      setMenu(database.menu_de);
      setLoading(false);
    }
  }, [language]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section id="hero">
          <header>
            <div
              className="nav-toggle"
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            >
              <p>{menu[0].title}</p>
              <span></span>
            </div>
            {openMenu ? (
              <ul className="absolute top-[75px] right-0 width-[100px] bg-zinc-900 flex flex-col m-0 p-4 space-y-1 border-[1px] border-[rgba(17, 17, 17, 0.15)] ">
                <li
                  className="text-base text-zinc-100 font-light hover:text-zinc-400"
                  onClick={() => setOpenMenu(false)}
                >
                  <a href="/dashboard">{menu[0].login}</a>
                </li>
                <li
                  className="text-base text-zinc-100 font-light hover:text-zinc-400"
                  onClick={() => setOpenMenu(false)}
                >
                  <a href="#about">{menu[0].bio}</a>
                </li>
                <li
                  className="text-base text-zinc-100 font-light hover:text-zinc-400"
                  onClick={() => setOpenMenu(false)}
                >
                  <a href="#events">{menu[0].events}</a>
                </li>
                <li
                  className="text-base text-zinc-100 font-light hover:text-zinc-400"
                  onClick={() => setOpenMenu(false)}
                >
                  <a href="#media">{menu[0].media}</a>
                </li>
                <li
                  className="text-base text-zinc-100 font-light hover:text-zinc-400"
                  onClick={() => setOpenMenu(false)}
                >
                  <a href="#contact">{menu[0].contact}</a>
                </li>
              </ul>
            ) : (
              ""
            )}
          </header>

          <div className="wrapper">
            <div className="welcome">
              <h1>
                VOICE<span>&</span>BRASS
              </h1>
              <p>"{titles[0].motto}"</p>
            </div>
            <div className="voiceandbrass">
              <LazyLoadImage src="assets/img/hero.png" alt="Voice&Brass" />
            </div>
          </div>
        </section>
      )}
    </>
  );
};
