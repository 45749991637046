import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth";

import { Pictures } from "./Components/Pictures";
import { Events } from "./Components/Events";
import { Videos } from "./Components/Videos";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
export const Dashboard = () => {
  const [warningMessage, setWarningMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // const [showElementBio, setShowElementBio] = useState(false);
  const [showElementEvents, setShowElementEvents] = useState(false);
  // const [showElementBandAch, setShowElementBandAch] = useState(false);
  // const [showElementSoloAch, setShowElementSoloAch] = useState(false);
  const [showElementPictures, setShowElementPictures] = useState(false);
  const [showElementVideos, setShowElementVideos] = useState(false);

  const auth = getAuth();

  const logOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful.");
        window.location.replace("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const visibility = (type) => {
    // if (type === "bio") setShowElementBio(!showElementBio);

    // if (type === "bandAch") setShowElementBandAch(!showElementBandAch);

    // if (type === "soloAch") setShowElementSoloAch(!showElementSoloAch);

    if (type === "events") setShowElementEvents(!showElementEvents);

    if (type === "videos") setShowElementVideos(!showElementVideos);

    if (type === "pictures") setShowElementPictures(!showElementPictures);
  };

  return (
    <section id="dashboard">
      <div className="w-full h-full bg-zinc-700">
        <div className="flex flex-col bg-zinc-700 items-center justify-center p-5 drop-shadow-md">
          <h1 className="text-2xl py-2 text-zinc-200 font-normal uppercase lg:text-2xl">
            <strong>VOICE</strong>&<strong>BRASS</strong> Dashboard
          </h1>
          <p className="py-2 text-zinc-300">
            Bejelentkezve: {auth._currentUser.email.toUpperCase()}
          </p>
        </div>

        <div className="flex flex-col absolute h-screen rounded-r-lg items-center justify-center top-0 w-46 -left-52 bg-zinc-800 hover:left-0 transition-all p-5 z-10">
          <div className="flex flex-col items-center justify-center absolute top-5">
            <button
              className="text-red-500 hover:text-red-400 uppercase"
              onClick={() => logOut()}
            >
              Kijelentkezés
            </button>
            <div className="absolute -right-16 -rotate-90">
              <FontAwesomeIcon className="text-2xl" icon={faEllipsis} />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center absolute bottom-5">
            <p className="text-white">
              <a href="http://www.hmartx.com" target="_blank" rel="noreferrer">
                HMARTX
                <strong>
                  <span>.</span>
                </strong>
                COM
              </a>
            </p>
          </div>

          <ul className="text-md text-zinc-200 text-center cursor-pointer uppercase">
            {/* <li
              onClick={() => visibility("bio")}
              className="m-2 bg-zinc-600 py-2 px-7 rounded-lg hover:bg-zinc-500"
            >
              Paragrafusok
            </li>
            <li
              onClick={() => visibility("bandAch")}
              className="m-2 bg-zinc-600 py-2 px-7 rounded-lg hover:bg-zinc-500"
            >
              Eredmények
            </li>
            <li
              onClick={() => visibility("soloAch")}
              className="m-2 bg-zinc-600 py-2 px-7 rounded-lg hover:bg-zinc-500"
            >
              Egyéni eredm.
            </li> */}
            <li
              onClick={() => visibility("events")}
              className="m-2 bg-zinc-600 py-2 px-7 rounded-lg hover:bg-zinc-500"
            >
              Események
            </li>
            <li
              onClick={() => visibility("pictures")}
              className="m-2 bg-zinc-600 py-2 px-7 rounded-lg hover:bg-zinc-500"
            >
              Képek
            </li>
            <li
              onClick={() => visibility("videos")}
              className="m-2 bg-zinc-600 py-2 px-7 rounded-lg hover:bg-zinc-500"
            >
              Videók
            </li>
          </ul>
        </div>

        <div className="w-full min-h-screen p-10">
          {successMessage && (
            <div className="text-md text-emerald-500">
              <p>{successMessage}</p>
            </div>
          )}
          {warningMessage && (
            <div className="text-md text-red-500">
              <p>{warningMessage}</p>
            </div>
          )}
          <div className="h-full bg-zinc-600 p-5 rounded-lg drop-shadow-sm">
            {/* {showElementBio ? <Paragraphs /> : ""}
            {showElementBandAch ? <BandAchievements /> : ""}
            {showElementSoloAch ? <SoloAchievements /> : ""} */}
            {showElementPictures ? <Pictures /> : ""}
            {showElementEvents ? <Events /> : ""}
            {showElementVideos ? <Videos /> : ""}
          </div>
        </div>
      </div>
    </section>
  );
};
