import React, { useState, useEffect } from "react";
import { Loading } from "../Loading/Loading";
import "./Events.css";
import { getData } from "../../utils/DatabaseActions";

export const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  // back current front

  useEffect(() => {
    getData("events", setEvents, setLoading);
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        events
          .slice(0)
          .reverse()
          .map((event) => (
            <li
              className="flex overflow-hidden relative mx-auto"
              key={event.id}
            >
              <a href={event.link} target="_blank" rel="noreferrer">
                <img
                  className="object-cover w-[300px] h-[200px]"
                  src={event.image}
                  alt={event.alt}
                />
                <div className="absolute bottom-0 left-0 text-center uppercase w-full">
                  <p className="text-zinc-50 font-bold bg-zinc-800 bg-opacity-80">
                    {event.name}
                  </p>
                  <p className="text-zinc-50 bg-zinc-800 bg-opacity-80">
                    {event.date.replaceAll("-", ".")}
                  </p>
                </div>
              </a>
            </li>
          ))
      )}
    </>
  );
};
