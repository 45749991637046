import React, { Fragment } from "react";
import "./NotFound.css";

export const NotFound = () => {
  return (
    <Fragment>
      <div className="notfound">
        <div className="wrapper">
          <h2>Az oldal nem található.</h2>

          <button>
            <a href="/">Vissza a kezdőlapra</a>
          </button>
        </div>
      </div>
    </Fragment>
  );
};
